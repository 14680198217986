<template>
  <v-container fluid>
    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-dialog v-model="revertFormDialog" max-width="500">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          {{ submitProperties.text }}
        </v-toolbar>

        <div class="pa-4">{{ submitProperties.msg }}</div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalRevertForm()"
          >
            Submit
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="revertFormDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="revertCaseDialog" max-width="500">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          Revert Case Back To Tab 3.0
        </v-toolbar>

        <div class="pa-4">Are you sure to revert this case?</div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="revertCase()"
          >
            Revert
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="revertCaseDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!--BOC:[contents]-->
    <v-card>
      <div>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR">
          <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title v-if="!loadingDialog">
            <div class="d-flex align-center text-white">
              {{ model.add.name }}
              &nbsp;
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" class="text-white">
                    mdi-information-slab-circle
                  </v-icon>
                </template>
                2 Complete Form + Grant Approval No. consider as complete form
              </v-tooltip>
            </div>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <!-- <span v-if="isHrdcAdminRoles && displayRevertBtn">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="hidden-xs-only"
                  color="orange lighten-2"
                  :loading="apiRevertCase.isLoading"
                  @click="revertCaseDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on"> mdi-file-undo </v-icon>
                </v-btn>
              </template>
              <span>
                Revert Case
                <br />
                (Back to send Tab 3.0)
              </span>
            </v-tooltip>
          </span> -->

          <span v-if="isHrdcAdminRoles && !btnDisabled">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="hidden-xs-only"
                  :color="submitProperties.color"
                  :loading="apiSubmit.isLoading"
                  @click="revertFormDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-file-document-arrow-right
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ submitProperties.text }}</span>
            </v-tooltip>
          </span>
        </v-toolbar>
      </div>

      <v-card
        class="pa-2 ma-2"
        :style="{ backgroundColor: HRDC_CARD_COLOR }"
        outlined
      >
        <v-row>
          <v-col cols="6">
            <!-- Programme Name -->
            <v-col class="d-flex" cols="12" sm="12" md="12">
              <v-text-field
                dense
                filled
                label="Programme Name"
                v-model="formData.programmeName"
                :error-messages="errorField.programmeName"
                disabled
              ></v-text-field>
            </v-col>

            <!-- Company Name -->
            <v-col class="d-flex" cols="12" sm="12" md="12">
              <v-text-field
                dense
                filled
                disabled
                label="Company Name"
                v-model="formData.companyName"
              ></v-text-field>
            </v-col>

            <!-- Event Date -->
            <v-col cols="12" sm="12" md="12">
              <v-menu
                ref="eventMenu"
                v-model="formData.eventMenu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    :disabled="true"
                    v-model="eventDate"
                    label="Event Date (YYYY-MM-DD)"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="errorField.eventDateRange"
                  ></v-text-field>
                </template>
                <v-date-picker
                  range
                  v-model="formData.eventDateRange"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="eventMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.eventMenu.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-col>

          <v-col cols="6">
            <!-- Refund Type -->
            <v-col class="d-flex" cols="12" sm="12" md="12">
              <v-select
                @change="changeRefundType()"
                v-model="formData.refundType"
                :error-messages="errorField.refundType"
                :items="[
                  {
                    text: 'Normal',
                    value: 1,
                  },
                  {
                    text: '70% / 30%',
                    value: 2,
                  },
                ]"
                dense
                filled
                label="Refund Type"
                append-icon="mdi-information"
              >
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-information</v-icon>
                    </template>
                    <span
                      >Manual Invoice will require 2 if refund type is
                      70/30</span
                    >
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>

            <!-- Request Invoice Amount -->
            <div v-show="requestManualInvoiceDisplay">
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-text-field
                  dense
                  filled
                  type="number"
                  label="Request Invoice Amount"
                  disabled
                  v-model="formData.requestManualInvoiceAmount"
                  :error-messages="errorField.requestManualInvoiceAmount"
                ></v-text-field>
              </v-col>
            </div>

            <!-- 70/30 -->
            <div v-show="seventyThirtyCase">
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-text-field
                  dense
                  filled
                  type="number"
                  label="70% Claim Amount"
                  v-model="formData.seventyPercent"
                  :error-messages="errorField.seventyPercent"
                ></v-text-field>
                &nbsp;
                <v-text-field
                  dense
                  filled
                  type="number"
                  label="30% Claim Amount"
                  v-model="formData.thirtyPercent"
                  :error-messages="errorField.thirtyPercent"
                ></v-text-field>
              </v-col>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <hr />

      <v-row>
        <v-col cols="6" style="border-right: 1px solid lightgrey">
          <v-card
            class="pa-2 ma-2"
            :style="{ backgroundColor: HRDC_CARD_COLOR }"
            outlined
          >
            <v-col>
              <v-app-bar flat outlined elevation="0">
                <span>
                  <span class="text-button font-weight-bold"
                    >Grant Details</span
                  >
                  <v-icon
                    style="cursor: pointer"
                    :color="caseIcon.grantIcon.color"
                  >
                    {{ caseIcon.grantIcon.icon }}
                  </v-icon>
                </span>
              </v-app-bar>
            </v-col>

            <v-col v-if="displayAutoCapturedData">
              <v-card elevation="0" color="blue lighten-4">
                <v-card-title class="">
                  <v-row>
                    <v-col cols="6" class="px-2 text-decoration-underline">
                      Grant Email Details
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <!-- Grant Email Grant Approval No. -->
                  <v-row>
                    <v-col cols="6" class="px-2 py-0"> Company Name: </v-col>
                    <v-col cols="6" class="px-2 py-0 font-weight-bold">
                      {{ grantEmailData.hrdcCompanyName }}
                    </v-col>
                  </v-row>

                  <!-- Grant Email Programme Name -->
                  <v-row>
                    <v-col cols="6" class="px-2 py-0"> Programme Name: </v-col>
                    <v-col cols="6" class="px-2 py-0 font-weight-bold">
                      {{ grantEmailData.programmeName }}
                    </v-col>
                  </v-row>

                  <!-- Grant Email Price -->
                  <v-row>
                    <v-col cols="6" class="px-2 py-0"> Price: </v-col>
                    <v-col cols="6" class="px-2 py-0 font-weight-bold">
                      {{ grantEmailData.price }}
                    </v-col>
                  </v-row>

                  <!-- Grant Email Grant Approval No. -->
                  <v-row>
                    <v-col cols="6" class="px-2 py-0">
                      Grant Approval No.:
                    </v-col>
                    <v-col cols="6" class="px-2 py-0 font-weight-bold">
                      {{ grantEmailData.grantApprovalNo }}
                    </v-col>
                  </v-row>

                  <!-- Grant Email Grant Approval Date -->
                  <v-row>
                    <v-col cols="6" class="px-2 py-0">
                      Grant Approval Date:
                    </v-col>
                    <v-col cols="6" class="px-2 py-0 font-weight-bold">
                      {{ grantEmailData.grantApprovalDate }}
                    </v-col>
                  </v-row>

                  <!-- Grant Email Subscription Date -->
                  <v-row>
                    <v-col cols="6" class="px-2 py-0">
                      Subscription Date:
                    </v-col>
                    <v-col cols="6" class="px-2 py-0 font-weight-bold">
                      {{ grantEmailData.trainingDate }}
                    </v-col>
                  </v-row>

                  <!-- Grant Email Attachments -->
                  <v-row>
                    <v-col cols="6" class="px-2 py-0"> Attachment: </v-col>
                    <v-col cols="6" class="px-2 py-0 font-weight-bold">
                      <v-btn
                        small
                        :color="HRDC_BLUE_THEME_COLOR"
                        outlined
                        @click="previewBlobAttachment()"
                      >
                        Preview
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                  <v-btn
                    class="text-dark"
                    color="green lighten-2"
                    small
                    @click="embedGrantData(true)"
                    :disabled="!disabledApprovalGrantEmailBtn"
                  >
                    Approve
                  </v-btn>
                  <v-btn
                    class="text-dark"
                    color="red lighten-2"
                    small
                    @click="embedGrantData(false)"
                    :disabled="disabledApprovalGrantEmailBtn"
                  >
                    Revert
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>

            <!-- Grant Approval No. -->
            <v-col class="d-flex" cols="12" sm="12" md="12">
              <v-text-field
                dense
                filled
                label="Grant Approval No."
                v-model="formData.grantApprovalNo"
                :error-messages="errorField.grantApprovalNo"
              >
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        :color="rareCaseGrantFlag ? 'green' : 'red'"
                        @click="toggleMarker"
                        v-on="on"
                      >
                        {{
                          rareCaseGrantFlag
                            ? "mdi-check-underline-circle"
                            : "mdi-close-octagon"
                        }}
                      </v-icon>
                    </template>
                    <span>{{
                      rareCaseGrantFlag
                        ? "Will Check Grant Format"
                        : "No Check Grant Format"
                    }}</span>
                    <!-- Tooltip content -->
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>

            <!-- Grant email attachment -->
            <v-col class="d-flex" cols="12">
              <v-col cols="9" class="pa-0">
                <div
                  v-cloak
                  @drop.prevent="
                    addDropFile($event, 'grantEmailAttachment', false)
                  "
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    dense
                    filled
                    small-chips
                    label="Grant Email Attachment"
                    accept=".pdf, image/*"
                    v-model="formData.grantEmailAttachment"
                    :error-messages="errorField.grantEmailAttachment"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
              <v-col class="pt-0" cols="3">
                <v-btn
                  x-large
                  outlined
                  :color="HRDC_BLUE_THEME_COLOR"
                  @click="previewAttachment('grantEmailAttachment')"
                >
                  Preview
                </v-btn>
              </v-col>
            </v-col>

            <!-- Grant Approval No. Submitted Date -->
            <v-col cols="12" sm="12" md="12">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    v-model="formData.grantApprovalNoSubmitDate"
                    :error-messages="errorField.grantApprovalNoSubmitDate"
                    label="Grant Approved Date (YYYY-MM-DD)"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.grantApprovalNoSubmitDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <!-- revertStatus -->
            <v-col class="d-flex" cols="12" sm="12" md="12">
              <v-select
                dense
                filled
                :items="grantRevertOpts"
                label="Grant Status"
                v-model="formData.revertStatus"
                :error-messages="errorField.revertStatus"
              ></v-select>
            </v-col>

            <!-- Remarks -->
            <v-col class="d-flex" cols="12" sm="12" md="12">
              <v-textarea
                dense
                filled
                auto-grow
                label="Grant Remarks"
                v-model="formData.remarks"
                :error-messages="errorField.remarks"
              ></v-textarea>
            </v-col>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card
            class="pa-2 ma-2"
            :style="{ backgroundColor: HRDC_CARD_COLOR }"
            outlined
          >
            <v-col>
              <v-app-bar flat outlined elevation="0">
                <span>
                  <span class="text-button font-weight-bold">Form Details</span>
                  <v-icon
                    style="cursor: pointer"
                    :color="caseIcon.formIcon.color"
                  >
                    {{ caseIcon.formIcon.icon }}
                  </v-icon>
                </span>
              </v-app-bar>
            </v-col>

            <!-- JD14 -->
            <v-col class="d-flex" cols="12">
              <v-col cols="9" class="pa-0">
                <div
                  v-cloak
                  @drop.prevent="addDropFile($event, 'jd14FileUrl')"
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    dense
                    filled
                    small-chips
                    label="JD14"
                    accept=".pdf"
                    v-model="formData.jd14FileUrl"
                    :error-messages="errorField.jd14FileUrl"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
              <v-col class="pt-0" cols="3">
                <v-btn
                  x-large
                  outlined
                  :color="HRDC_BLUE_THEME_COLOR"
                  @click="previewAttachment('jd14FileUrl')"
                >
                  Preview
                </v-btn>
              </v-col>
            </v-col>

            <!-- T3 -->
            <v-col class="d-flex" cols="12">
              <v-col cols="9" class="pa-0">
                <div
                  v-cloak
                  @drop.prevent="addDropFile($event, 't3FileUrl')"
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    dense
                    filled
                    small-chips
                    label="T3"
                    accept=".pdf"
                    v-model="formData.t3FileUrl"
                    :error-messages="errorField.t3FileUrl"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
              <v-col class="pt-0" cols="3">
                <v-btn
                  x-large
                  outlined
                  :color="HRDC_BLUE_THEME_COLOR"
                  @click="previewAttachment('t3FileUrl')"
                >
                  Preview
                </v-btn>
              </v-col>
            </v-col>

            <!-- Attendance Form -->
            <v-col class="d-flex" cols="12">
              <v-col cols="9" class="pa-0">
                <div
                  v-cloak
                  @drop.prevent="addDropFile($event, 'attendanceForm')"
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    dense
                    filled
                    small-chips
                    label="Attendance Form"
                    accept=".pdf"
                    v-model="formData.attendanceForm"
                    :error-messages="errorField.attendanceForm"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
              <v-col class="pt-0" cols="3">
                <v-btn
                  x-large
                  outlined
                  :color="HRDC_BLUE_THEME_COLOR"
                  @click="previewAttachment('attendanceForm')"
                >
                  Preview
                </v-btn>
              </v-col>
            </v-col>

            <!-- Form Submitted Date -->
            <v-col cols="12" sm="12" md="12">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    v-model="formData.formSubmitDate"
                    :error-messages="errorField.formSubmitDate"
                    label="Form Revert Date (YYYY-MM-DD)"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.formSubmitDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu2.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <!-- Form revertStatus -->
            <v-col class="d-flex" cols="12" sm="12" md="12">
              <v-select
                dense
                filled
                :items="formRevertOpts"
                label="Form Status"
                v-model="formData.formRevertStatus"
                :error-messages="errorField.formRevertStatus"
              ></v-select>
            </v-col>

            <!-- Remarks -->
            <v-col class="d-flex" cols="12" sm="12" md="12">
              <v-textarea
                dense
                filled
                auto-grow
                label="Form Remarks"
                v-model="formData.formRemarks"
                :error-messages="errorField.formRemarks"
              ></v-textarea>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <HrdcLoader
      :loadingDialog="loadingDialog"
      :loadingText="loadingText"
    ></HrdcLoader>
  </v-container>
</template>

<script>
import moment from "moment";
import Api from "@/objects/api";
import { mapState } from "vuex";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_tax_summit/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
    submitProperties() {
      let status = null;
      if (
        this.formData.revertStatus == 0 ||
        this.formData.formRevertStatus == 0
      ) {
        status = {
          text: "Submit Incomplete Form",
          color: "error",
          msg: "Please be informed that this form is incomplete, do you wish to proceed ?",
        };
      } else {
        if (
          this.formData.jd14FileUrl &&
          this.formData.t3FileUrl &&
          this.formData.grantApprovalNo &&
          this.formData.attendanceForm
        ) {
          status = {
            text: "Submit Complete Form",
            color: "success",
            msg: "Please confirm that you wish to submit this form & HRDC Invoice will be generate automatically",
          };
        } else {
          status = {
            text: "Submit Incomplete Form",
            color: "error",
            msg: "Please be informed that this form is incomplete, do you wish to proceed ?",
          };
        }
      }

      return status;
    },
    eventDate() {
      return this.formData.eventDateRange.join(" - ");
    },
    seventyThirtyCase() {
      if (this.formData.refundType == 2) {
        return true;
      }
      return false;
    },
    caseIcon() {
      let grantIcon = {},
        formIcon = {};
      if (
        this.formData.jd14FileUrl &&
        this.formData.t3FileUrl &&
        this.formData.formRevertStatus &&
        this.formData.formSubmitDate &&
        this.formData.attendanceForm
      ) {
        formIcon.icon = "mdi-check-circle";
        formIcon.color = "green";
      } else {
        formIcon.icon = "mdi-close-circle";
        formIcon.color = "red";
      }

      if (
        this.formData.grantApprovalNo &&
        this.formData.revertStatus &&
        this.formData.grantApprovalNoSubmitDate
      ) {
        grantIcon.icon = "mdi-check-circle";
        grantIcon.color = "green";
      } else {
        grantIcon.icon = "mdi-close-circle";
        grantIcon.color = "red";
      }

      return {
        formIcon,
        grantIcon,
      };
    },
    displayAutoCapturedData() {
      if (this.grantEmailData.hrdcCompanyName) {
        return true;
      }

      return false;
    },
  },
  props: ["params"],
  data: () => ({
    apiSubmit: new Api(),
    apiGetApplicationAttachment: new Api(),
    apiRevertCase: new Api(),
    apiDownloadOrPreviewAttachment: new Api(),
    apiGetXeroRefreshToken: new Api(),
    apiRefreshXeroToken: new Api(),
    apiGetXeroTenantId: new Api(),
    apiRequestXeroAccessToken: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    loadingDialog: false,
    loadingText: null,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu2: false,
    currentStagePriority: 0,
    formData: {
      applicationNo: null,
      programmeName: null,
      grantApprovalNo: null,
      grantApprovalNoSubmitDate: null,
      formSubmitDate: null,
      jd14FileUrl: null,
      t3FileUrl: null,
      remarks: null,
      formRemarks: null,
      revertStatus: null, // Page revertGrantStatus
      formRevertStatus: null,
      dbRevertGrantStatus: null, // Database revertGrantStatus
      eventMenu: false,
      eventDateRange: [],
      refundType: null,
      seventyPercent: null,
      thirtyPercent: null,
      totalPrice: null,
      companyName: null,
      requestManualInvoiceAmount: null,
      grantEmailAttachment: null,
      oriCompanyName: null,
      oriPrice: null,
      oriGrantApprovalNo: null,
      oriProgrammeName: null,
      oriGrantApprovalDate: null,
      oriEmailAttachment: null,
      oriGrantStatus: null,
      attendanceForm: null,
    },
    errorField: {
      applicationNo: null,
      programmeName: null,
      grantApprovalNo: null,
      grantApprovalNoSubmitDate: null,
      formSubmitDate: null,
      jd14FileUrl: null,
      t3FileUrl: null,
      remarks: null,
      formRemarks: null,
      revertStatus: null,
      formRevertStatus: null,
      eventMenu: null,
      eventDateRange: null,
      refundType: null,
      seventyPercent: null,
      thirtyPercent: null,
      totalPrice: null,
      requestManualInvoiceAmount: null,
      grantEmailAttachment: null,
      attendanceForm: null,
    },
    revertFormDialog: false,
    editMode: false,
    btnDisabled: false,
    requestManualInvoiceDisplay: false,
    rareCaseGrantFlag: true,
    originalGrantData: {
      emailAttachment: null,
    },
    grantEmailData: {
      emailAttachment: null,
      hrdcCompanyName: null,
      price: null,
      grantApprovalNo: null,
      programmeName: null,
      grantApprovalDate: null,
      trainingDate: null,
    },
    disabledApprovalGrantEmailBtn: true,
    filesMapping: [
      {
        fileIndex: "jd14FileUrl",
        fileKey: "JD14",
      },
      {
        fileIndex: "t3FileUrl",
        fileKey: "T3",
      },
      {
        fileIndex: "grantEmailAttachment",
        fileKey: "HRDC_GRANT_EMAIL",
      },
      {
        fileIndex: "attendanceForm",
        fileKey: "ATTENDANCE_FORM",
      },
      {
        fileIndex: "attendanceList",
        fileKey: "ATTENDANCE_LIST",
      },
    ],
  }),
  async created() {
    this.showLoadingDialog();
    this.formData.applicationNo = this.$route.query._ausk;
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    this.serviceDataAssignation(this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/revert_form/create-revert-form`,
        method: "post",
      },
      apiGetApplicationAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-application-attachment`,
        method: "post",
      },
      apiRevertCase: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/revert_form/revert-case`,
        method: "post",
      },
      apiDownloadOrPreviewAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/azure-download-or-preview-application-attachment`,
      },
      apiGetXeroRefreshToken: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/xero/get-xero-token?date=${moment().format(
          "YYYY-MM-DD"
        )}`,
      },
      apiRefreshXeroToken: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/xero/update-xero-token`,
        method: "put",
      },
      apiRequestXeroAccessToken: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/xero/request-xero-access-token`,
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;
    this.currentStagePriority =
      this.hrdcData.stageIndex[
        this.$store.state[this.service.key].data.stageId
      ];

    this.rolesAssignation(this.auth.roles);
    if (!this.isHrdcAdminRoles) {
      this.alertError.push("You are not allowed to perform this action!");
      this.btnDisabled = true;
    }

    const grantApprovalStage =
      this.hrdcData.stageIndex[
        this.hrdcData.stagePriority.grantApprovalNoSubmitted
      ];

    const totallyIncompleteStage =
      this.hrdcData.stageIndex[
        this.hrdcData.stagePriority.clientRevertFormIncomplete
      ];

    let stageErrorMsg = null;
    let checkGreaterStage = this.hrdcData.stageSlug.clientRevertT3Form;
    if (
      this.currentStagePriority > grantApprovalStage &&
      this.currentStagePriority <= totallyIncompleteStage
    ) {
      stageErrorMsg = "This form is partially filled in";
      checkGreaterStage = grantApprovalStage;
    }

    this.checkFormAlreadyFilledGte(
      this.currentStagePriority,
      checkGreaterStage,
      stageErrorMsg
    );

    this.checkFormNotYetFilledLesser(
      this.currentStagePriority,
      this.hrdcData.stageSlug.sentConfirmationEmailToClient
    );

    // Get is there any uploaded attachment for jd14/t3.
    this.apiGetApplicationAttachment.setParams({
      applicationNo: this.$route.query._ausk,
    });
    this.apiGetApplicationAttachment.fetch();
    this.apiGetApplicationAttachment.setCallbackCompleted((response) => {
      try {
        const { data } = response;

        // General details.
        let grantApprovalNo = null;
        let grantApprovalNoSubmitDate = null;

        // Assign latest event date range.
        const eventDate = data.ApplicationLog.find(
          (item) =>
            item.stageId ==
            this.hrdcData.stagePriority.subscriptionDateScheduled
        );

        if (eventDate !== undefined) {
          this.formData.eventDateRange = [
            moment(eventDate.startDate, "YYYY-MM-DD HH:mm:ssZ").format(
              "DD/MM/YYYY"
            ),
            moment(eventDate.endDate, "YYYY-MM-DD HH:mm:ssZ").format(
              "DD/MM/YYYY"
            ),
          ];
        }

        this.formData.companyName = data.oriCompanyName.toUpperCase();
        this.formData.programmeName = data.programmeName;
        this.formData.totalPrice = data.totalPrice;
        this.formData.refundType = data.refundType;
        this.formData.seventyPercent = Number(data.seventyPercentPrice).toFixed(
          2
        );
        this.formData.thirtyPercent = Number(data.thirtyPercentPrice).toFixed(
          2
        );
        this.formData.requestManualInvoiceAmount = data.totalPrice; // Default price.

        // Assign original data to re-append if user click 'Revert'.
        this.formData.oriCompanyName = data.oriCompanyName.toUpperCase();
        this.formData.oriPrice = data.totalPrice;
        this.formData.oriGrantApprovalNo = null;
        this.formData.oriProgrammeName = data.programmeName;
        this.formData.oriGrantApprovalDate = null;
        this.formData.oriEmailAttachment = null;
        this.formData.dbRevertGrantStatus = data.revertGrantStatus;

        // Append auto captured grant email data & display.
        const grantEmailHookData = JSON.parse(data.grantEmailHookData);

        if (grantEmailHookData) {
          this.grantEmailData.hrdcCompanyName =
            grantEmailHookData.hrdcCompanyName;
          this.grantEmailData.price = grantEmailHookData.price;
          this.grantEmailData.grantApprovalNo =
            grantEmailHookData.grantApprovalNo;
          this.grantEmailData.programmeName = grantEmailHookData.programmeName;
          this.grantEmailData.grantApprovalDate = moment(
            grantEmailHookData.grantApprovalDate
          ).format("YYYY-MM-DD");
          this.grantEmailData.emailAttachment =
            grantEmailHookData.emailAttachment;
          this.grantEmailData.trainingDate =
            grantEmailHookData.trainingDate ?? "-";
        }

        if (this.editMode) {
          // Grant Details.
          const grantRemarkArr = [
            this.hrdcData.stagePriority.grantApprovalNoSubmitted,
          ];
          grantApprovalNo = data.grantApprovalNo;

          // Check whether grant approval no. has been submitted or not.
          const checkGrant = data.ApplicationLog.find((obj) =>
            grantRemarkArr.includes(obj.stageId)
          );

          if (checkGrant !== undefined) {
            if (checkGrant.startDate) {
              grantApprovalNoSubmitDate = moment(checkGrant.startDate).format(
                "YYYY-MM-DD"
              );
            }
          }

          this.formData.grantApprovalNo = grantApprovalNo;

          // Check rare case flag.
          if (grantApprovalNo) {
            this.rareCaseGrantFlag =
              !this.hasMoreThanTwoUnderscores(grantApprovalNo);
          }

          this.formData.grantApprovalNoSubmitDate = grantApprovalNoSubmitDate;
          this.formData.revertStatus = data.revertGrantStatus;
          if (data.ApplicationLog.length > 0) {
            const grantRemarks = data.ApplicationLog.find((item) =>
              grantRemarkArr.includes(item.stageId)
            );
            if (grantRemarks) {
              this.formData.remarks = grantRemarks.remarks;
            }
          }

          // Form details.
          const jd14T3FormSubmittedStageId =
            this.hrdcData.stagePriority.jd14T3FormSubmitted;

          for (const item of data.ApplicationAttachment) {
            // Create a Blob object from the data
            const blob = new Blob([new Uint8Array(item.dataArray)]);

            // Create the File object
            const file = new File([blob], item.fileName, {
              type: "application/pdf",
            });

            const fileObj = this.filesMapping.find(
              (fileMap) => fileMap.fileKey == item.type
            );
            if (fileObj) {
              this.formData[fileObj.fileIndex] = file;
            }
          }

          let jd14T3FormSubmittedDate = null;

          const checkFormSubmitted = data.ApplicationLog.find(
            (obj) => obj.stageId == jd14T3FormSubmittedStageId
          );

          if (checkFormSubmitted !== undefined) {
            jd14T3FormSubmittedDate = moment(
              checkFormSubmitted.startDate
            ).format("YYYY-MM-DD");
          }

          this.formData.formSubmitDate = jd14T3FormSubmittedDate;
          this.formData.formRevertStatus = data.revertFormStatus;
          if (data.ApplicationLog.length > 0) {
            const formRemarks = data.ApplicationLog.find(
              (item) => item.stageId == jd14T3FormSubmittedStageId
            );
            if (formRemarks) {
              this.formData.formRemarks = formRemarks.remarks;
            }
          }

          if (data.refundType == 1) {
            this.requestManualInvoiceDisplay = true;
          }

          // If got assign request manual invoice amount, then assign request manual invoice amount, else use application pricing.
          if (data.requestManualInvoiceAmount) {
            this.formData.requestManualInvoiceAmount =
              data.requestManualInvoiceAmount;
          }

          // Assign original data to re-append if user click 'Revert'.
          this.formData.oriGrantApprovalNo = grantApprovalNo;
          this.formData.oriGrantApprovalDate = grantApprovalNoSubmitDate;
          this.formData.oriGrantStatus = data.revertGrantStatus;
        } else {
          this.requestManualInvoiceDisplay = true;
        }

        this.hideLoadingDialog();
      } catch (err) {
        console.log(err);
      }
    });
  },
  methods: {
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    addDropFile(e, key, strictPdf = true) {
      let file = e.dataTransfer.files[0];

      if (strictPdf) {
        if (!file.type.match("pdf.*")) {
          this.errorField[key] = "Please select pdf file format";
          return;
        }
      }

      this.formData[key] = file;
    },
    modalRevertForm() {
      this.revertFormDialog = !this.revertFormDialog;
      this.submit();
    },
    changeRefundType() {
      if (this.formData.refundType == 1) {
        this.formData.seventyPercent = null;
        this.formData.thirtyPercent = null;

        // Enable request manual invoice amount.
        this.requestManualInvoiceDisplay = true;
      } else {
        // Assign split amount.
        const totalPrice = this.formData.totalPrice;
        this.formData.seventyPercent = (totalPrice * 0.7).toFixed(2);
        this.formData.thirtyPercent = (totalPrice * 0.3).toFixed(2);

        // Disable request manual invoice amount.
        this.requestManualInvoiceDisplay = false;
      }
    },
    revertCase() {
      this.apiRevertCase.setParams({
        applicationUuid: this.formData.applicationNo,
      });
      this.apiRevertCase.setCallbackCompleted((response) => {
        try {
          this.showLoadingDialog();
          // this.revertCaseDialog = false;
          const { status, data } = response;

          if (!status) {
            this.$store.dispatch("showMessage", {
              message: data,
              messageType: "error",
              timeout: 2000,
            });
            return;
          }

          this.$store.dispatch("showMessage", {
            message: data,
            messageType: "success",
            timeout: 2000,
          });

          this.$router.push({
            name: "HrdcTaxSummitApplication",
            query: {
              viewId: this.$store.state[this.service.key].data.viewId,
            },
          });
        } catch (err) {
          console.log(err);
        } finally {
          this.hideLoadingDialog();
        }
      });
      this.apiRevertCase.fetch();
    },
    redirectBack() {
      let route = "HrdcTaxSummitApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state[this.service.key].data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    toggleMarker() {
      this.rareCaseGrantFlag = !this.rareCaseGrantFlag;
    },
    hasMoreThanTwoUnderscores(word) {
      return word.split("_").length > 3;
    },
    async previewBlobAttachment() {
      this.showLoadingDialog();

      let fileUrl = this.grantEmailData.emailAttachment;
      fileUrl = fileUrl.replace(/\.[^.]+$/, (match) => match.toLowerCase());

      await this.$axios
        .get(this.apiDownloadOrPreviewAttachment.url, {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            filePath: fileUrl,
          },
        })
        .then((response) => {
          const blobUrl = URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );

          window.open(blobUrl, "_blank");

          this.hideLoadingDialog();
        })
        .catch((error) => {
          if ((error.response.status == 404) | true) {
            this.$store.dispatch("showMessage", {
              message:
                "Failed to Preview/Download attachment, kindly contact administrator",
              messageType: "error",
              timeout: 2000,
            });
          }
          this.hideLoadingDialog();
        });
    },
    previewAttachment(fileType) {
      try {
        if (!this.formData[fileType]) {
          this.$store.dispatch("showMessage", {
            message: "No Preview Available",
            messageType: "info",
          });
          return;
        }

        let url = null;
        let newWindow = null;
        switch (fileType) {
          default:
            url = URL.createObjectURL(this.formData[fileType]);
            newWindow = window.open(url, "_blank");
            break;
        }

        if (newWindow) {
          newWindow.onload = function () {
            // Revoke the object URL when the window is closed
            newWindow.addEventListener("beforeunload", function () {
              URL.revokeObjectURL(url);
            });
          };
        }
      } catch {
        this.previewBlobAttachment();
      }
    },
    embedGrantData(approval) {
      this.showLoadingDialog();

      this.disabledApprovalGrantEmailBtn = !this.disabledApprovalGrantEmailBtn;

      // Embed
      if (approval) {
        this.formData.grantApprovalNo = this.grantEmailData.grantApprovalNo;
        this.formData.revertStatus = 1;
        // this.formData.programmeName = this.grantEmailData.programmeName;
        this.formData.grantApprovalNoSubmitDate =
          this.grantEmailData.grantApprovalDate;
        this.formData.grantEmailAttachment = {
          name: "Grant Email Attachment.pdf",
        };
      }

      // Discharge
      if (!approval) {
        this.formData.grantApprovalNo = this.formData.oriGrantApprovalNo;
        // this.formData.programmeName = this.formData.oriProgrammeName;
        this.formData.grantApprovalNoSubmitDate =
          this.formData.oriGrantApprovalDate;
        this.formData.grantEmailAttachment = null;
        this.formData.revertStatus = this.formData.oriGrantStatus;
      }

      this.hideLoadingDialog();
    },
    successRedirect(data) {
      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: "ViewHrdcTaxSummitApplication",
        query: {
          viewId: this.$store.state[this.service.key].data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
      this.$store.dispatch("showMessage", {
        message: data,
        messageType: "success",
        timeout: 2000,
      });
    },
    async submit() {
      this.showLoadingDialog();
      this.loadingText = "Submitting Grant & Form...";
      // Generate Form Data to handle multipart/form-data
      const fd = new FormData();
      fd.append("applicationUuid", this.formData.applicationNo);
      fd.append("remarks", this.formData.remarks);
      fd.append("programmeName", this.formData.programmeName);
      fd.append("grantApprovalNo", this.formData.grantApprovalNo);
      fd.append(
        "grantApprovalNoSubmitDate",
        this.formData.grantApprovalNoSubmitDate
      );
      fd.append("referById", this.auth.uuid);
      fd.append("jd14FileUrl", this.formData.jd14FileUrl);
      fd.append("t3FileUrl", this.formData.t3FileUrl);
      fd.append("revertStatus", this.formData.revertStatus); // Grant Revert Status
      fd.append("refundType", this.formData.refundType);
      fd.append("seventyPercent", this.formData.seventyPercent);
      fd.append("thirtyPercent", this.formData.thirtyPercent);
      fd.append("totalPrice", this.formData.totalPrice);
      fd.append("editMode", this.editMode);
      fd.append("formSubmitDate", this.formData.formSubmitDate);
      fd.append("formRevertStatus", this.formData.formRevertStatus); // Form Revert Status
      fd.append("formRemarks", this.formData.formRemarks);
      fd.append(
        "requestManualInvoiceAmount",
        this.formData.requestManualInvoiceAmount
      );
      fd.append("rareCaseGrantFlag", this.rareCaseGrantFlag);
      fd.append("attendanceForm", this.formData.attendanceForm);
      fd.append("hrdcType", this.hrdcType);
      fd.append("uploadFiles", [
        "jd14FileUrl",
        "t3FileUrl",
        "grantEmailAttachment",
        "attendanceForm",
      ]);

      // If not File type, then will not do any checking for this file.
      let grantEmailAttachment = this.grantEmailData.emailAttachment;

      // If is edit mode & grant issue, then remove the grantEmailAttachment.
      if (this.editMode && !this.formData.revertStatus) {
        grantEmailAttachment = null;
      }

      let isGrantFileInstance = false;
      if (this.formData.grantEmailAttachment instanceof File) {
        grantEmailAttachment = this.formData.grantEmailAttachment;
        isGrantFileInstance = true;
      }
      fd.append("grantEmailAttachment", grantEmailAttachment);
      fd.append("isGrantFileInstance", isGrantFileInstance);
      fd.append("oriCompanyName", this.formData.companyName);

      this.apiSubmit.setParams(fd);
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          const applicationData = response.application;

          if (!response.status) {
            this.showErrorMessage(response);
            if (response.message) {
              this.$store.dispatch("showMessage", {
                message: response.message,
                messageType: "error",
                timeout: 2000,
              });
            }

            this.hideLoadingDialog();
            return false;
          }

          // Response success, Grant Completed, Form Completed, current stage is before completed stage, HRDC Invoice No. is empty only need to generate HRDC invoice.
          if (
            response.status &&
            !applicationData.manualInvoiceToHrdc &&
            this.formData.revertStatus &&
            this.formData.formRevertStatus &&
            this.currentStagePriority <
              this.hrdcData.stageSlug.clientRevertT3Form
          ) {
            this.loadingText = "Generating HRDC Invoice...";
            const applicationLogData = applicationData.ApplicationLog.find(
              (item) =>
                item.stageId ==
                  this.hrdcData.stagePriority.subscriptionDateScheduled &&
                item.status == null
            );
            const trainingStartDate = this.convertDateFormat(
              applicationLogData.startDate
            );
            const trainingEndDate = this.convertDateFormat(
              applicationLogData.endDate
            );
            const trainingDate = `${trainingStartDate} to ${trainingEndDate}`;

            // Generate HRDC Invoice when completed.
            this.apiGetXeroRefreshToken.setCallbackCompleted(
              async (xeroTokenRes) => {
                try {
                  if (!xeroTokenRes || !xeroTokenRes.status) {
                    throw new Error(
                      "Unable to generate HRDC Invoice due to XERO invalid response, please contact administrator for assistance (1)"
                    );
                  }

                  // Check got valid refresh token or not, if yes, can direct call XERO API.
                  if (xeroTokenRes.data) {
                    const refreshToken = xeroTokenRes.data.token;

                    const token = await this.xeroRequestAccessToken(
                      this.apiRequestXeroAccessToken.url,
                      this.$store.state.auth.data.token,
                      refreshToken
                    );

                    const accessToken = token.data.access_token;

                    // Refresh the new refresh token in db.
                    this.apiRefreshXeroToken.setParams({
                      refresh_token: token.data.refresh_token,
                      token_id: xeroTokenRes.data.id,
                    });
                    this.apiRefreshXeroToken.fetch();

                    const connectionResponse = await this.xeroRequestTenantId({
                      url: `${
                        this.$service[this.service.key]
                      }/v1/en/console/xero/get-xero-tenant-id`,
                      headers: {
                        Authorization: `Bearer ${this.$store.state.auth.data.token}`,
                      },
                      postData: {
                        accessToken,
                      },
                    });

                    if (!connectionResponse || !connectionResponse.status) {
                      throw new Error(
                        "Unable to generate HRDC Invoice due to XERO invalid response, please contact administrator for assistance (2)"
                      );
                    }

                    const connectionData = connectionResponse.data;
                    const connection =
                      connectionData[connectionData.length - 1];
                    const tenantId = connection.tenantId;

                    const [
                      ContactResponse,
                      AccountResponse,
                      TrackingCategoryResponse,
                    ] = await Promise.all([
                      // GET Contacts details.
                      this.xeroCallApi({
                        authorization: this.$store.state.auth.data.token,
                        service_key: this.model.serviceKey,
                        access_token: accessToken,
                        xero_tenant_id: tenantId,
                        api_type: "get_contacts",
                        method: "get",
                        postData: null,
                      }),
                      // GET AccountCode.
                      this.xeroCallApi({
                        authorization: this.$store.state.auth.data.token,
                        service_key: this.model.serviceKey,
                        access_token: accessToken,
                        xero_tenant_id: tenantId,
                        api_type: "get_accounts",
                        method: "get",
                        postData: null,
                      }),
                      // Get tracking categories.
                      this.xeroCallApi({
                        authorization: this.$store.state.auth.data.token,
                        service_key: this.model.serviceKey,
                        access_token: accessToken,
                        xero_tenant_id: tenantId,
                        api_type: "get_tracking_categories",
                        method: "get",
                        postData: null,
                      }),
                    ]);

                    const { ContactID } = ContactResponse;
                    const { Code, TaxType } = AccountResponse;
                    const trackingCatObj = TrackingCategoryResponse;

                    const TaxRateResponse = await this.xeroCallApi({
                      authorization: this.$store.state.auth.data.token,
                      service_key: this.model.serviceKey,
                      access_token: accessToken,
                      xero_tenant_id: tenantId,
                      api_type: "get_tax_rates",
                      method: "get",
                      postData: null,
                      url_suffix: `${TaxType}`,
                    });

                    const [taxRate] = TaxRateResponse.data.TaxRates.map(
                      (item) => item.EffectiveRate
                    );

                    // Start to do looping based on refund type (normal/70+30)
                    const LineItems = [];
                    let xeroProcessCount = 0;
                    const descriptionData = {
                      programmeName: this.formData.programmeName,
                      trainingDate,
                      companyName: applicationData.oriCompanyName,
                      grantApprovalNo: this.formData.grantApprovalNo,
                      note: null,
                    };

                    if (this.formData.refundType == 1) {
                      const {
                        unitAmount: requestInvoiceUnitAmount,
                        taxAmount: requestInvoiceTaxAmount,
                      } = this.calculateLineAmounts(
                        this.formData.requestManualInvoiceAmount,
                        taxRate
                      );

                      // Normal Claim
                      LineItems.push({
                        Description:
                          this.generateHrdcInvoiceDesc(descriptionData),
                        Quantity: 1,
                        LineAmount: requestInvoiceUnitAmount,
                        TaxType,
                        AccountCode: Code,
                        TaxAmount: requestInvoiceTaxAmount,
                        Tracking: [
                          {
                            ...trackingCatObj.divisonTracking,
                          },
                          {
                            ...trackingCatObj.productTracking,
                          },
                        ],
                      });
                    }

                    if (this.formData.refundType == 2) {
                      // 70+30 Claim
                      const {
                        unitAmount: requestInvoice70UnitAmount,
                        taxAmount: requestInvoice70TaxAmount,
                      } = this.calculateLineAmounts(
                        this.formData.seventyPercent,
                        taxRate
                      );
                      const {
                        unitAmount: requestInvoice30UnitAmount,
                        taxAmount: requestInvoice30TaxAmount,
                      } = this.calculateLineAmounts(
                        this.formData.thirtyPercent,
                        taxRate
                      );

                      const additionalNote30 = `RM ${this.formData.thirtyPercent} - 30 % Upfront Payment`;
                      descriptionData.note = additionalNote30;
                      const Description30 =
                        this.generateHrdcInvoiceDesc(descriptionData);

                      descriptionData.note = `${additionalNote30}, RM ${this.formData.seventyPercent} - 70% Payment Upon Claim Approval.`;
                      const Description70 =
                        this.generateHrdcInvoiceDesc(descriptionData);

                      LineItems.push(
                        // 70%
                        {
                          Description: Description70,
                          Quantity: 1,
                          LineAmount: requestInvoice70UnitAmount,
                          TaxType,
                          AccountCode: Code,
                          TaxAmount: requestInvoice70TaxAmount,
                          Tracking: [
                            {
                              ...trackingCatObj.divisonTracking,
                            },
                            {
                              ...trackingCatObj.productTracking,
                            },
                          ],
                        },
                        // 30%
                        {
                          Description: Description30,
                          Quantity: 1,
                          LineAmount: requestInvoice30UnitAmount,
                          TaxType,
                          AccountCode: Code,
                          TaxAmount: requestInvoice30TaxAmount,
                          Tracking: [
                            {
                              ...trackingCatObj.divisonTracking,
                            },
                            {
                              ...trackingCatObj.productTracking,
                            },
                          ],
                        }
                      );
                    }

                    for (const LineItem of LineItems) {
                      // Construct data needed to request api to XERO.
                      const invoicePostData = {
                        Invoices: [
                          {
                            Type: this.INVOICE_TYPE,
                            Contact: {
                              ContactID,
                            },
                            Reference: this.TAXPOD_INVOICE_REFERENCE,
                            LineItems: [LineItem],
                            Status: this.getInvoiceStatus(),
                            DueDate: moment().format("YYYY-MM-DD"),
                            BrandingThemeID:
                              process.env.VUE_APP_XERO_BRANDING_THEME_ID,
                          },
                        ],
                      };

                      // Call XERO generate invoice api.
                      const generateInvoiceRes = await this.xeroCallApi({
                        authorization: this.$store.state.auth.data.token,
                        service_key: this.model.serviceKey,
                        access_token: accessToken,
                        xero_tenant_id: tenantId,
                        api_type: "post_invoices",
                        method: "post",
                        postData: invoicePostData,
                      });
                      const generateInvoiceResData = generateInvoiceRes.data;

                      if (
                        !generateInvoiceRes ||
                        generateInvoiceRes.status !== 200 ||
                        generateInvoiceResData.Status !== "OK"
                      ) {
                        throw new Error(
                          "HRDC Invoice created, but failed to upload invoice to system, please contact administrator for assistance"
                        );
                      }

                      // Construct GET HRDC Invoice attachments data.
                      const invoiceDetails =
                        generateInvoiceResData.Invoices.map((item) => {
                          return {
                            InvoiceID: item.InvoiceID, // A xero unique Invoice uuid
                            InvoiceNumber: item.InvoiceNumber, // e.g. YGC-XXXXXX
                            InvoiceDate: item.DueDateString,
                          };
                        });

                      // Call XERO GET Invoice via pdf api. (XERO API return PDF as pdf binary string type).
                      const hrdcInvoiceAtchRes = await this.xeroCallApi({
                        authorization: this.$store.state.auth.data.token,
                        service_key: this.model.serviceKey,
                        access_token: accessToken,
                        xero_tenant_id: tenantId,
                        api_type: "get_invoices",
                        method: "get",
                        postData: null,
                        url_suffix: `${invoiceDetails[0].InvoiceID}`,
                        accept_header: "application/pdf",
                        config: {
                          responseType: "arraybuffer", // Response received will be an PDF type of array buffer.
                          responseEncoding: "binary", // Response encoding using binary.
                        },
                      });

                      // Construct data to store in azure blob storage.
                      const arrayBuffer = hrdcInvoiceAtchRes.data;
                      const blob = new Blob([arrayBuffer], {
                        type: "application/pdf",
                      });
                      const file = new File([blob], "hrdc_invoice.pdf", {
                        type: "application/pdf",
                      });

                      const fd = new FormData();
                      fd.append("invoice", file);
                      fd.append("xeroProcessCount", xeroProcessCount);
                      fd.append("refundType", this.formData.refundType);
                      fd.append("applicationUuid", this.formData.applicationNo);
                      fd.append("referById", this.auth.uuid);
                      fd.append(
                        "hrdcInvoiceNo",
                        invoiceDetails[0].InvoiceNumber
                      );
                      fd.append("invoiceDate", invoiceDetails[0].InvoiceDate);
                      fd.append("clientNoXero", this.HRDC_CLIENT_NO);
                      fd.append(
                        "updateToStageId",
                        this.hrdcData.stagePriority.manualInvoiceToHrdc
                      );
                      fd.append(
                        "updateToStageName",
                        this.hrdcData.stageSlugToStageName.manualInvoiceToHrdc
                      );
                      fd.append(
                        "invoiceDateScheduled",
                        this.hrdcData.stagePriority.invoiceDateScheduled
                      );

                      // Store the HRDC Invoice PDF binary data to azure blob storage & update log.
                      await this.$axios
                        .post(
                          `${
                            this.$service[this.service.key]
                          }/v1/en/console/manual_invoice/process-hrdc-flow`,
                          fd,
                          {
                            headers: {
                              Authorization: `Bearer ${this.$store.state.auth.data.token}`,
                            },
                          }
                        )
                        .then((response) => {
                          try {
                            const { status, data } = response.data;
                            if (status) {
                              xeroProcessCount++;

                              if (xeroProcessCount == LineItems.length) {
                                this.successRedirect(data);
                              }
                            }
                          } catch (err) {
                            console.log(err);
                          }
                        });
                    }
                  } else {
                    // Else, need to autorize app and get a new refresh token.
                    const xeroParams = {
                      redirectCallbackUri: this.xeroRedirectUri,
                      stateType: this.$route.query._ausk,
                    };
                    const childWindow = window.open(
                      this.requestAuthorizeApp(xeroParams)
                    );
                    var timer = setInterval(() => {
                      if (childWindow.closed) {
                        this.successRedirect(response.data);
                        clearInterval(timer);
                      }
                    }, 500);
                  }
                } catch (err) {
                  console.log(err);
                  this.alertError.push(
                    "Failed to generate HRDC Invoice due to invalid credentials & this form is submitted, please contact admin for support!"
                  );
                  return false;
                } finally {
                  this.hideLoadingDialog();
                }
              }
            );

            this.apiGetXeroRefreshToken.fetch();
          } else {
            this.hideLoadingDialog();
            this.successRedirect(response.data);
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmit.fetch();
    },
  },
};
</script>
